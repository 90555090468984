import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {} from '@angular/google-maps';
import { map, Observable, tap } from 'rxjs';

import { RequestCache } from '@logic-suite/shared/cache';
import { retryOn504, SharedObservable } from '@logic-suite/shared/utils';

import { ApplicationStorageService } from '@logic-suite/shared/storage';
import { TableService } from '@logic-suite/shared/table';
import { ConstantsService } from '../../shared/constants.service';
import { GroupFilterFunction } from '../../shared/filter/filter.model';
import { PageOptions } from '../../shared/paginator/pager.model';
import { CustomerService } from '../customer/customer.service';
import {
  CategorizedMeter,
  InvoiceLines,
  MeterCost,
  MeteringPoint,
  MeteringPointData,
  MeteringPointReadings,
  MeterReading,
  PostHourlyValue,
  ReattachedMeteringPoint,
} from './meter.model';

const time = 8000;
@Injectable({ providedIn: 'root' })
export class MeterService {
  storage = inject(ApplicationStorageService);
  table = inject(TableService);
  dataOptions = {
    totalPages: 1,
    pageStart: 0,
    pageSize: this.storage.getItem('bs-meter.pageSize', 100),
    ...(this.table.hasItem(`bs-meter.filter`) && { filterBy: this.table.getItem(`bs-meter.filter`) }),
  } as PageOptions;

  getCustomer = this.customer.getCustomer;
  getContract = this.customer.getCustomerContract;
  findCustomer = (customerID: number) => {
    return this.customer.getCustomers({ filterBy: [{ customerID }] }).pipe(map(v => v.data[0]));
  };

  constructor(
    private http: HttpClient,
    private cache: RequestCache,
    private customer: CustomerService,
    private constants: ConstantsService,
  ) {}

  getMeters(options = {} as PageOptions): Observable<MeteringPointData> {
    const opts = Object.assign({ pageStart: 0, pageSize: 100, totalPages: 1 }, options);
    opts.totalPages = 1;
    if (opts.sortDirection) {
      if (opts.sortDirection === 'asc') opts.sortDirection = 'ascending';
      if (opts.sortDirection === 'desc') opts.sortDirection = 'descending';
    }
    if (opts.filterBy && opts.filterBy.length) {
      opts.filterBy = opts.filterBy.map(f => JSON.stringify(f));
    }
    if (Array.isArray(opts.search)) {
      opts.search = opts.search.filter(s => s.replace(',', '').trim().length > 1);
      if (opts.search.length == 0) {
        delete opts.search;
      }
    }
    return this.http.get<MeteringPointData>('/api/bs/MeteringPoint', { params: opts as any }).pipe(retryOn504());
  }

  @SharedObservable()
  getMeter(meterId: number, contractID?: string) {
    return this.http
      .get<MeteringPoint>(`/api/bs/MeteringPoint/${meterId}`, {
        params: {
          ...(contractID ? { meteringPointContractID: contractID } : {}),
        },
      })
      .pipe(retryOn504());
  }

  getGlobalValues() {
    return this.constants.getGlobalValues();
  }

  readElHub(val: any, contractID: number, force = false): Observable<MeteringPoint> {
    return this.http.get<MeteringPoint>(`/api/bs/MeteringPoint/Info/${val}`, {
      params: {
        ...(contractID ? { meteringPointContractID: contractID } : {}),
        ...(force ? { refreshFromElhub: true } : {}),
      },
    });
  }

  cloneAndReattach(meter: MeteringPoint) {
    return this.http
      .post<ReattachedMeteringPoint>(`/api/bs/MeteringPointContract/Clone/${meter.meteringPointContractID}`, null)
      .pipe(
        map(mc => {
          return Object.assign(meter, {
            meteringPointContractID: mc.meteringPointContractID,
            contractID: mc.contractID,
            meteringPointRegisterID: mc.meteringPointRegisterID,
            meteringPointContractStartDateMs: mc.startDateMs,
            meteringPointContractStartDateStatus: mc.startDateStatus,
            meteringPointContractEndDateMs: mc.endDateMs,
            meteringPointContractEndDateStatus: mc.endDateStatus,
            meteringPointContractTerminationReason: mc.terminationReason,
            meteringPointContractReleaseEndDateMs: mc.releaseEndDateMs,
            meteringPointContractPowerVendor: mc.powerVendor,
          } as MeteringPoint);
        }),
      );
  }

  getMeterCost(meter: MeteringPoint, monthMs: number, tariffID?: number) {
    return this.http
      .get<MeterCost>(`/api/bs/MeteringPointCost/${meter.meteringPointContractID}`, {
        params: {
          monthMs: monthMs,
          ...(tariffID ? { gridTariffID: tariffID } : {}),
        },
      })
      .pipe(
        map(meterCost => {
          // prettier-ignore
          const costElements = [
            ...(meterCost.gridFixedCost != null ? [{
                costElement: 'GridFixedCost',
                cost: meterCost.gridFixedCost / meterCost.consumptionGrid,
                amount: meterCost.gridFixedCost,
              }] : []),
            ...(meterCost.gridEnergyCost != null ? [{
                costElement: 'GridEnergyCost',
                cost: meterCost.gridEnergyCost / meterCost.consumptionGrid,
                amount: meterCost.gridEnergyCost,
              }] : []),
            ...(meterCost.gridPeakCost != null ? [{
                costElement: 'GridPeakCost',
                cost: meterCost.gridPeakCost / meterCost.consumptionGrid,
                amount: meterCost.gridPeakCost,
              }] : []),
            ...(meterCost.gridReactiveCost != null ? [{
                costElement: 'GridReactiveCost',
                cost: meterCost.gridReactiveCost / meterCost.consumptionGrid,
                amount: meterCost.gridReactiveCost,
              }] : []),
            ...(meterCost.tax != null ? [{
                costElement: 'Tax',
                cost: meterCost.tax / meterCost.consumptionGrid,
                amount: meterCost.tax,
              }] : []),
          ];
          return {
            name: '210 - Grid',
            cost: costElements.reduce((acc, c) => (acc += c.cost), 0),
            amount: costElements.reduce((acc, c) => (acc += c.amount), 0),
            costElements,
          } as InvoiceLines;
        }),
      );
  }

  saveMeter(value: MeteringPoint) {
    return this.http.post<MeteringPoint>('/api/bs/MeteringPoint', value).pipe(
      tap(() => {
        this.cache.invalidate('/api/bs/PeriodicField/');
        this.cache.invalidate(`/api/bs/Customer/${value.customerID}`);
        this.cache.invalidate(`/api/bs/InvoiceGroup/${value.customerID}`);
      }),
    );
  }

  invalidateCodes() {
    this.cache.invalidate('/api/bs/Code');
  }

  meteringPointReading(meterID: number, amount: number) {
    return this.http
      .get<MeteringPointReadings>(`/api/bs/MeteringPointReading/${meterID}`, {
        params: { ...(amount ? { amount: amount } : {}) },
      })
      .pipe(
        map(res => {
          const readings = res.meteringPointReadings;
          res.meteringPointReadings = readings.map(r => {
            r.endDateMs = new Date(r.endDateMs);
            return r;
          });
          return res;
        }),
      );
  }

  saveMeteringPointReading(reading: MeterReading) {
    return this.http.post<MeterReading>('/api/bs/MeteringPointReading', reading).pipe(
      tap(() => {
        this.cache.invalidate(`/api/bs/MeteringPointReading/${reading.meteringPointRegisterID}`);
      }),
    );
  }

  removeMeteringPointReading(reading: MeterReading): Observable<unknown> {
    return this.http.delete(`/api/bs/MeteringPointReading/${reading.meteringPointReadingID}`).pipe(
      tap(() => {
        this.cache.invalidate(`/api/bs/MeteringPointReading/${reading.meteringPointRegisterID}`);
      }),
    );
  }

  groupFilters(): GroupFilterFunction {
    return (filterKey: string): string => {
      const [category, values] =
        Object.entries(CategorizedMeter).find(([category, values]) => values.includes(filterKey)) || [];
      return category ?? '08-Other';
    };
  }

  saveHourlyValues(meter: MeteringPoint, values: PostHourlyValue[]): Observable<any> {
    return this.http.post(`/api/bs/MeteringPointValue/${meter.meteringPointRegisterID}`, values);
  }
}
